$(document).ready(function() {
	new WOW().init();
	$("#testimonial").owlCarousel({
		navigation: true, // Show next and prev buttons
		singleItem: true,
		autoPlay:true,
		stopOnHover:true,
		navigationText: ["<i class='fas fa-long-arrow-alt-left'></i>","<i class='fas fa-long-arrow-alt-right'></i>"]
	});
    $('.grid').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
    });
});
